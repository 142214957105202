export default {
  async DescargarMaterialParte (Vue, idparteTrabajo, formData) {
    await Vue.$api.call('parteTrabajo.descargarMaterial', {
      idparte_trabajo: idparteTrabajo,
      idalmacen: formData.idalmacen,
    })
  },
  async DescargarMaterialTodosPartes (Vue, formData, idalmacenTecnico) {
    const resp = await Vue.$api.call(
      'parteTrabajo.selectPartesDescargar', { idalmacen: idalmacenTecnico }
    )
    const todosPartes = resp.data.result.dataset
    for (const parte of todosPartes) {
      await this.DescargarMaterialParte(Vue, parte.idparte_trabajo, formData)
    }
  },
}
